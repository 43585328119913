import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import Loader from "../../../../components/Loader";
// import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import apiCall from "../../../../api/Api";
// import '../../../flags.css';

const List = () => {
  const [products, setProducts] = useState(null); 
  const [currentOption, setCurrentOption] = useState({name:'pending',code:1}); 
  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await apiCall("GET", "bids");
        console.log(response, 'bids response');
        response && setProducts(response[currentOption?.name])
      } catch (error) {
        console.error("Failed", error);
      }
    }
    getCompanies()
    
  }, [currentOption]);
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true

    },
    { 
      name:'job.description',
      title:'Job Description',
      sortable:true

    },
    // { 
    //   name:'job.target_radius',
    //   title:'Job Radius',
    //   sortable:true

    // },
    { 
      name:'job.category_name',
      title:'Skill Required',
      sortable:true
    },
    // { 
    //   name:'geek.id',
    //   title:'Geek Id',
    //   sortable:true

    // },
    { 
      name:'geek.user.people.first_name',
      title:'Geek Name',
      sortable:true

    },
    { 
      name:'part_price',  
      title:'Part Price',
      sortable:true
    },
    { 
      name:'service_price',  
      title:'Service Price',
      sortable:true
    },
    // { 
    //   name:'is_genuine_parts',  
    //   title:'Service Price',
    //   sortable:true
    // },
    // { 
    //   name:'status',  
    //   title:'Status',
    //   showFilter:true,
    //   type:'status',
    //   showFilterElement:true,
    //   sortable:false,
    //   filterableStatuses:['Scheduled', 'Confirmed', 'Completed','Cancelled']
    // },
  ]; 

  const handleCustomFilters = (value)  => {
    console.log('option',value)
    setCurrentOption(value)
  }


  const customFilters = () => (
    <React.Fragment>
      <Dropdown
        value={currentOption}
        options={[
        {
          code:1,
          name:'pending', 
        },
        {
          code:2,
          name:'rejected',
        },
        {
          code:3,
          name:'accepted',
        }
      ]}
        onChange={(e) => handleCustomFilters(e.value)}
        // itemTemplate={statusItemTemplate}
        optionLabel="name"
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "20rem" }}
      />
    </React.Fragment>
  );
  // <Button icon="pi pi-plus-circle" rounded outlined className="m-1" onClick={() => handleCustomFilters(rowData)} />

  if(products === null) return <Loader />
  return (
    <div>
      {products && (
        <Company
          title="Bookings"
          data={products}
          column={column}
          // addRoute="/job/add"
          // showExportButton={true}
          showCustomFilter={customFilters}
          detailRoute={{route: 'bookings',apiUrl:'bids'}} showDetailsRoute={true}
          // toggleFilters={['Scheduled', 'Confirmed', 'Completed','Cancelled']}
        />
      )}
    </div>
  );
};

export default List;
