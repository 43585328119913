import React from 'react';
import { Controller } from "react-hook-form";
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputSwitch } from 'primereact/inputswitch';

 const SkillAdd = ({fields,onTypeChange,oneOffCostHandler,errors,control,register,setValue,main}) => {
  

const getFormErrorMessage = (name) => {
  return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};

const dropDownChangeHandler = (field,e,value) => {
  if(value?.customHandler){
    field.onChange(e.value)
  onTypeChange && onTypeChange(e.value)
  }else{
    field.onChange(e.value)
  }
  
}

const onToggleHandler = (field,e,value)  => {
  console.log('toggle')
  if(value?.customHandler){
    field.onChange(e.value)
    oneOffCostHandler && oneOffCostHandler(e.value)
  }else{
    field.onChange(e.value)
  }
}

const handleImageUpload = async (e,name) => {
  const file = e.files[0];
  console.log(file,'file')
  setValue(name,e.files[0])
}
  const body = (value) => {
    switch (value.type) {
      case "text":
        return (
          value.condition && (
              <Col xs={12} md={6} lg={4} xl={3} className="mb-5" style={{width:'250px'}}>
                  <FloatLabel>
                      <InputText
                          {...register(value.name)}
                          type={value.type}
                          defaultValue={value?.value}
                          className={
                              errors[value.name]
                                  ? "block peer rounded-[5px] w-full m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32] focus:ring-1 focus:ring-[#C93B32]"
                                  : "block peer rounded-[5px] border-[#AEBBCD] w-full mt-3 m-1 focus:outline-none focus:ring-1"
                          }
                      />
                      <label htmlFor={value.name} style={{ width: "auto" }}>
                          {value.placeholder}
                      </label>
                  </FloatLabel>
                  <span className="place-self-start text-[14px] text-[#C93B32]">
                      {errors[value.name]?.message}
                  </span>
              </Col>
          )
      );

      case "dropdown":
        return   (
        value.condition && (
              <Col xs={12} md={6} lg={4} xl={3} className="mb-5">
                <FloatLabel className="w-full">
                    <Controller
                        name={value.name}
                        control={control}
                        render={({ field }) => (
                            <div className="flex w-full">
                                <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) => dropDownChangeHandler(field, e, value)}
                                    options={value.options}
                                    optionLabel="name"
                                    focusInputRef={field.ref}
                                    optionValue="code"
                                    className="border border-[#AEBBCD] rounded-md"
                                    filter
                                    showClear
                                     style={{width:'250px',height:'38px', marginTop:'6%'}}
                                />
                                {getFormErrorMessage(field.name)}
                            </div>
                        )}
                    />
                    <label htmlFor={value.name} className='m-auto'> {value.placeholder} </label>
                </FloatLabel>
            </Col>
         ) );

      case "multi-select":
        return (
          <div className={`col-${value.column}`}>
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white font-bold text-left"
            >
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <>
                  <MultiSelect
                    id={field.name}
                    //  name="value"
                    value={field.value}
                    options={value.options}
                    onChange={(e) => field.onChange(e.value)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder={value.placeholder}
                    maxSelectedLabels={3}
                    className="w-full border border-[#AEBBCD] mt-5"
                    filter
                    showClear
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
        );

      case "image":
        return (
          // <div className={`col-${value.column}`}>
       (  value.condition &&   <Col xs={12} md={4} xl={4} className="mb-5">
             {/* <FloatLabel> */}
            {/* <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label> */}
            {/* <div className="card"> */}
              <FileUpload
                {...register(value.name)}
                // ref={{...register(value.name)}}
                name={value.name}
                // url={"/api/upload"}
                multiple={value.multiple}
                accept="image/*"
                maxFileSize={1000000}
                onSelect={(e) => handleImageUpload(e,value.name)}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                }
              />
            {/* </div> */}
            {/* </FloatLabel> */}
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
         ) );

      case "textarea":
        return (
          // <div className="col-xl-12">
          //   <label
          //     htmlFor={value.name}
          //     className="text-md text-black dark:text-white font-bold text-left"
          //   >
          //     {value.placeholder}
          //   </label>
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel>
            <textarea
              {...register(value.name)}
              rows="4"
              cols="80"
              className={
                errors[value.name]
                  ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
              }
            ></textarea>
                <label htmlFor="username" style={{ width: "200px" }}>
                {value.placeholder}
              </label>
            </FloatLabel>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
            </Col>

        );

      case "datetime":
        return (
          <Col
          xs={12}
          md={4}
          xl={4}
          className="w-full"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
           <FloatLabel className="w-full">
           
            <Controller
              name={value.name}
              control={control}
              rules={{ required: "Date is required." }}
              render={({ field, fieldState }) => (
                <div>
                  <Calendar
                    inputId={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    dateFormat="dd/mm/yy"
                    className="rounded border-[#AEBBCD] w-[25rem] focus:outline-none focus:ring-1"
                    showTime
                    hourFormat="12"
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
             <label
              htmlFor={value.name}
              // className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            </FloatLabel>
          </Col>
        );

      case "toggle":
        return (
          value.condition && (
          <Col
            xs={12}
            md={4}
            xl={4}
            className="ml-5 w-100"
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
          >
            <label className="" htmlFor={value.name}>
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <div className="">
                  <InputSwitch
                    name={field.name}
                    // value={field.value || false}
                    checked={field.value || false}
                    onChange={(e) => onToggleHandler(field,e,value) } 
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </Col>
        ));
        
      case "heading":
          return   value.condition && <div style={{width:'75vw',marginBottom:'2%',marginTop:'3%', fontSize:18}}> <h4 className='font-bold h-2'>{value.placeholder}</h4> </div>
        

      case "break":
          return (
            <Col
              xs={12}
              md={4}
              xl={4}
              // className="ml-5 w-100"
              // style={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
             <br/>
            </Col>
          );
  
      default:
        return null;
    }
  };

  

  return (
    <div style={{ margin: "18px" }} className="mt-5">
      {main ? (
        <div className="flex flex-wrap">
          {fields?.map((field) => (
            <React.Fragment key={field.name}>
              <Row>{body(field)}</Row>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <Card>
          <div className="flex flex-wrap">
            {fields?.map((field) => (
              <React.Fragment key={field.name}>
                <Row>{body(field)}</Row>
              </React.Fragment>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
}


export default SkillAdd;