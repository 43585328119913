import React, { useState , useEffect} from "react";
// import Form from "../../../../components/Form";
import * as yup from "yup";
import GeekAdd from "./GeekAdd";
import apiCall from "../../../../api/Api";

const Add = () => {
  const [visa_ids, setVisaIds] = useState(null);
  const [visaId, setVisaId] = useState(null);
  const [users_ids, setUsersIds] = useState(null);

  const [skills, setSkillsList] = useState(null);


  useEffect(() => {
    // ProductService.getProducts().then((data) => setProducts(data));
    const getSkills = async () => {
      try {
        const response = await apiCall("GET", "all-skills");
        let skl = response?.map(sk => (
          {
            name: sk.name,
            code : sk.id
          }
      ))
        setSkillsList(skl)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getSkills()
    
  }, []);

  const onUserSelectChange = (val) => {
    console.log(val, "users");
    setUsersIds(val);
  };

  const onVisaTypeChange = (val) => {
    console.log(val, "VISA TYPE");
    setVisaIds(val);
  };
  const handleVisaType = (value) => {
    console.log('triggered',value)
    setVisaId(value)
  }

  const schema = yup.object().shape({
    // email: yup.string().required('Email is required'),
    // first_name: yup.string().required('First name is required'),
    // last_name: yup.string().required('Last name is required'),
    // is_online: yup.boolean().required('Online status is required'),
    // status: yup.boolean().required('Status message is required'),
    // addr1: yup.string().required('Address is required'),
    // addr2: yup.string().required('Street Address is required'),
    // country: yup.string().required('Country is required'),
    // state: yup.string().required('State is required'),
    // city: yup.string().required('City is required'),
    // zip_code: yup.string().required('Postal Code is required'),
    // lat: yup.string().required('Latitude is required'),
    // long: yup.string().required('Longitude is required'),
    // dob: yup.date().required('Date of birth is required'),
    // gender: yup.string().oneOf(['male', 'female'], 'Gender is required').required('Gender is required'),
    // linkedin_profile: yup.string().url('Enter a valid URL').required('LinkedIn profile is required'),
    // eid_front_side_image: yup.mixed().required('EID front end pic is required'),
    // eid_back_side_image: yup.mixed().required('EID back end pic is required'),
    // password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    // mobile: yup.string().matches(/^[0-9]+$/, 'Mobile number must be digits only').min(10, 'Mobile number must be at least 10 digits').required('Mobile number is required'),
    // phone: yup.string().matches(/^[0-9]+$/, 'Phone number must be digits only').min(10, 'Phone number must be at least 10 digits').required('Phone number is required'),
    // visa_type: yup.number().oneOf([1, 2, 3], 'Select a valid visa type').required('Visa type is required'),
    // work_license_front_image: yup.mixed().when('visa_type', {
    //   is: 1,
    //   then: yup.mixed().required('License front side pic is required')
    // }),
    // work_license_back_image: yup.mixed().when('visa_type', {
    //   is: 1,
    //   then: yup.mixed().required('License back side pic is required')
    // }),
    // noc_image: yup.mixed().when('visa_type', {
    //   is: 3,
    //   then: yup.mixed().required('NOC front side pic is required')
    // }),
    // company_email: yup.string().email('Enter a valid email').when('visa_type', {
    //   is: 2,
    //   then: yup.string().required('Company email is required')
    // }),
    // company_id: yup.string().when('visa_type', {
    //   is: 2,
    //   then: yup.string().required('Company ID is required')
    // }),
  });

  const fields = [
    { type: "text", name: "email", placeholder: "Email", column: 4 },
    { type: "text", name: "first_name", placeholder: "First Name", column: 4 },
    { type: "text", name: "last_name", placeholder: "Last Name", column: 4 },
    { type: "text", name: "password", placeholder: "Password", column: 4 },
    { type: "text", name: "mobile", placeholder: "Mobile", column: 4 },
    { type: "text", name: "phone", placeholder: "Phone", column: 4 },
    { type: "text", name: "adrr_1", placeholder: "Address", column: 4 },
    { type: "text", name: "adrr_2", placeholder: "Street Address", column: 4 },
    { type: "text", name: "country", placeholder: "Country", column: 4 },
    { type: "text", name: "state", placeholder: "State", column: 4 },
    { type: "text", name: "city", placeholder: "City", column: 4 },
    { type: "text", name: "zip_code", placeholder: "Postal Code", column: 4 },
    { type: "text", name: "lat", placeholder: "Latitude", column: 4 },
    { type: "text", name: "long", placeholder: "Longitude", column: 4 },
    { type: "text", name: "linkedin_profile", placeholder: "Linkedin Profile", column: 4 },
    { type: "dropdown", name: "visa_type", placeholder: "Select Visa Type", column: 4, onChange: onVisaTypeChange, value: visa_ids, options:[
      { code: 'freelancer', name: 'Freelancer' },
      { code: 'it_company', name: 'IT Company Employee' },
      { code: 'second_job', name: 'Second Job' }
    ]},
    {
      type: "dropdown",
      name: "gander",
      placeholder: "Gender",
      column: 4,
      onChange: onUserSelectChange,
      value: users_ids,
      options:[
        {
          code:'male',
          name:'Male'
        },
        {
          code:'female',
          name:'Female'
        }
      ]
    },
   
    { type: "datetime", name: "dob", placeholder: "Date of Birth", column: 4 },
    visaId === 'it_company' && { type: "text", name: "company_email", placeholder: "Company Email", column: 4 },
    visaId === 'it_company' && { type: "text", name: "company_id", placeholder: "Company ID", column: 4 },
    { type: "toggle", name: "status", placeholder: "Status", column: 4 },
    { type: "toggle", name: "is_online", placeholder: "Online Visibility", column: 4 },

    visaId !== null && { type: "heading", name: "heading", placeholder: "Media Images", column: 4 },


    { type: "image", name: "eid_front_side_image", placeholder: "EID Front Image", column: 4 },
    { type: "image", name: "eid_back_side_image", placeholder: "EID Back Image", column: 4 }, 
    visaId === 'freelancer' &&  { type: "image", name: "work_license_front_image", placeholder: "Work License Front Image", column: 4 },
    visaId === 'freelancer' && { type: "image", name: "work_license_back_image", placeholder: "Work License Back Image", column: 4 },
    visaId === 'second_job' && { type: "image", name: "noc_image", placeholder: "NOC Front Image", column: 4 },
    // { type: "space", name: "space", placeholder: "", column: 4 },

    { type: "heading", name: "skill_heading", placeholder: "Geek Skills", column: 4 },


    { type: "dynamicFields", name: "skills", placeholder: "Skills", column: 4,
      options:skills
    }
  ];

  return (
    // skills && <GeekAdd title="Geek" schema={schema} fields={fields} getVisaType={handleVisaType} />
    <GeekAdd title="Geek" schema={schema} fields={fields} apiRoute='geeks' getVisaType={handleVisaType}
    dynamicFieldsArray={[{ skill_id: '', self_score: 1,one_off_cost_remote:0,one_off_cost_onsite:0,per_hour_cost_onsite:0,per_hour_cost_remote:0,other_details:'',certificate:null }]}  
    />
  );
};

export default Add;
