import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null);
  const [loader, setloader] = useState(false); 
  useEffect(() => {
    // ProductService.getProducts().then((data) => setProducts(data));
   
    getGeeks()
    
  }, []);
  const getGeeks = async () => {
    try {
      setloader(true)
      const response = await apiCall("GET", "geeks");
      console.log(response, 'geeks response');
      setProducts(response)
      setloader(false)
      // navigate('/companies');
    } catch (error) {
      console.error("Failed to Add:", error);
    }
  }
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'first_name',
      title:'First Name',
      sortable:true
    },
    { 
      name:'last_name',
      title:'Last Name',
      sortable:true
    },
    { 
      name:'description',
      title:'Email',
      sortable:true
    },
    // { 
    //   name:'code',
    //   title:'EID'
    // },
    // { 
    //   name:'geek_skills',
    //   title:'Skills',
    //   type:'formatter',
    //   keys:['skill_name'],
    //   sortable:true
    // },
    // { 
    //   name:'eid_issue_date',
    //   title:'EID Issuance'
    // },
    // { 
    //   name:'eid_expire_date',
    //   title:'EID Expire'
    // },
    { 
      name:'mobile',
      title:'Mobile',
      sortable:true
    },
    { 
      name:'status',
      title:'Status',
      type:'status',
      kind:'easy-edit',
      postUrl:'/geek-status',
      dropdownOptions:[
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "InActive", value: "inactive" },
        { label: "Suspended", value: "suspended" },
      ]
    },
    { 
      name:'is_online',
      title:'Is Online',
      type:'status'
    },
    
    
    // { 
    //   name:'transport_type',
    //   title:'Transport Type'
    // },
    // { 
    //   name:'rate_ph',
    //   title:'Rate P/H'
    // },
    // { 
    //   name:'max_rate',
    //   title:'Max Rate'
    // },
    // { 
    //   name:'min_rate',
    //   title:'Min Rate'
    // },
  ]; 

      // Loader here
      if (loader === true) return <Loader/>;
  return (
    <div>
      <Company title="Geeks" data={products} column={column} addRoute='/geek/add' showFurtherDetailButton={true} showReviewButton={true}
         detailRoute={{route: 'geeks',apiUrl:'geeks'}} showDetailsRoute={true}
         fetchDataAgain={getGeeks}
      />
    </div>
  );
};

export default List;
