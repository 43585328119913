import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import '../../../main.css';
import '../../../flags.css';
import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null); 
  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await apiCall("GET", "role-list");
        console.log(response, 'roles response');
        setProducts(response)
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getRoles()
    
  }, []);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'name',
      title:'Name',
      sortable:true
    },
    // { 
    //   name:'first_name',
    //   title:'First Name'
    // },
    // { 
    //   name:'last_name',
    //   title:'Last Name'
    // },
    // { 
    //   name:'country',
    //   title:'Country'
    // },
    // { 
    //   name:'mobile',
    //   title:'Mobile'
    // },
    
    // { 
    //   name:'gander',
    //   title:'Gender'
    // },
    // { 
    //   name:'target_radius',
    //   title:'Target Raduis'
    // },
    // { 
    //   name:'status',
    //   title:'Status',
    //   type:'status'
    // },
  ]; 

      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title="Roles" data={products} column={column} addRoute='/role/add'  detailRoute={{route: 'roles',apiUrl:'role-edit'}} showDetailsRoute={true}/>
    </div>
  );
};

export default List;
