import React, { useState } from "react";
import Form from "../../../../components/Form";
import * as yup from "yup";

const Add = () => {
  const [users_ids, setUsersIds] = useState(null);


  const onUserSelectChange = (val) => {
    console.log(val, "users");
    setUsersIds(val);
  };

  const schema = yup.object({
    // target_radius: yup.string().required("Raduis is required."),
    // status: yup.string().required("Status is required."),
    // user_id: yup.string().required("Trade lisence is required."),
    // people_id: yup.string().required("Trade lisence is required."),
  });

  const fields = [
    {
      type: "text",
      name: "first_name",
      placeholder: "First Name",
      column: 4,
    },
    {
      type: "text",
      name: "last_name",
      placeholder: "Last Name",
      column: 4,
    },
    {
      type: "text",
      name: "email",
      placeholder: "Email",
      column: 4,
    },
    {
      type: "text",
      name: "password",
      placeholder: "Password",
      column: 4,
    },
    {
      type: "text",
      name: "mobile",
      placeholder: "Mobile",
      column: 4,
    },
    {
      type: "text",
      name: "adrr_1",
      placeholder: "Address",
      column: 4,
    },
    {
      type: "text",
      name: "adrr_2",
      placeholder: "Street Address",
      column: 4,
    },
    {
      type: "text",
      name: "country",
      placeholder: "Country",
      column: 4,
    },
    {
      type: "text",
      name: "state",
      placeholder: "State",
      column: 4,
    },
    {
      type: "text",
      name: "city",
      placeholder: "City",
      column: 4,
    },
    {
      type: "text",
      name: "zip_code",
      placeholder: "Zip Code",
      column: 4,
    },
    {
      type: "text",
      name: "lat",
      placeholder: "Latitude",
      column: 4,
    },
    {
      type: "text",
      name: "long",
      placeholder: "Longitude",
      column: 4,
    },
    {
      type: "text",
      name: "target_radius",
      placeholder: "Target Radius",
      column: 4,
    },
    // {
    //   type: "text",
    //   name: "status",
    //   placeholder: "Status",
    //   column: 4,
    // },
    {
      type: "dropdown",
      name: "gander",
      placeholder: "Gender",
      column: 4,
      onChange: onUserSelectChange,
      value: users_ids,
      options:[
        {
          code:'male',
          name:'Male'
        },
        {
          code:'female',
          name:'Female'
        }
      ]
    },
    {
      type: "datetime",
      name: "dob",
      placeholder: "Date of Birth",
      column: 4,
    },
   
   
  ]; 
  return  <Form title="Customer" redirectUrl='customers' schema={schema} fields={fields} apiRoute='customers' />
};

export default Add;
