import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./DetailPage.css";
import apiCall from "../api/Api";

const DetailPage = ({ apiUrl, titleKey, subtitleKey, imageKey, actions }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDetail(apiUrl);
  }, [apiUrl]);

  const getDetail = async (url) => {
    try {
      const response = await apiCall("GET", url);
      setData(response);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch details:", error);
    }
  };

  const CardHeader = () => (
    <div className="p-3 bg-orange-500 text-white rounded-t-md shadow-md">
      <h2 className="text-2xl font-bold capitalize">{data[titleKey]}</h2>
    </div>
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderContent = (key, value) => {
    // Render for array values
    if (Array.isArray(value)) {
      return (
        <Accordion key={key} className="mb-3 col-span-full">
          <AccordionTab
            header={<h3 className="text-lg font-semibold break-words">{formatKey(key)}</h3>}
          >
            <div className="grid grid-cols-1 gap-4">
              {value.map((item, index) => (
                <div key={index} className="p-3 bg-gray-100 rounded-md shadow-sm break-words">
                  {typeof item === "object" && item !== null
                    ? Object.keys(item).map((subKey) => renderContent(subKey, item[subKey]))
                    : <span className="break-words">{item}</span>}
                </div>
              ))}
            </div>
          </AccordionTab>
        </Accordion>
      );
    }
  
    // Render for object values
    else if (typeof value === "object" && value !== null) {
      return (
        <Accordion key={key} className="mb-3 col-span-full">
          <AccordionTab
            header={<h3 className="text-lg font-semibold break-words">{formatKey(key)}</h3>}
          >
            <div className="grid grid-cols-5 gap-4">
              {Object.keys(value).map((subKey) => (
                <div
                  key={subKey}
                  className="my-2 px-2 break-words"
                  style={{ minWidth: "150px", maxWidth: "220px" }}
                >
                  <strong className="text-gray-700 break-words">{formatKey(subKey)}:</strong>{" "}
                  <span className="text-gray-900 break-words">
                    {subKey.toLowerCase().includes("created_at") && value[subKey]
                      ? formatDate(value[subKey])
                      : value[subKey] || "N/A"}
                  </span>
                </div>
              ))}
            </div>
          </AccordionTab>
        </Accordion>
      );
    }
  
    // Render for simple values
    else {
      return (
        <div
          key={key}
          className="mb-2 px-2 break-words"
          style={{ minWidth: "150px", maxWidth: "220px" }}
        >
          <strong className="text-gray-700 break-words">{formatKey(key)}:</strong>{" "}
          <span className="text-gray-900 break-words">
            {key.toLowerCase().includes("created_at") && value
              ? formatDate(value)
              : value || "N/A"}
          </span>
        </div>
      );
    }
  };
  

  return (
    <div className="detail-page container mx-auto p-6">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ProgressSpinner />
        </div>
      ) : (
        data && (
          <Card className="rounded-md shadow-lg overflow-hidden" header={CardHeader} style={{ maxWidth: "100%" }}>
            <div className="p-6 bg-white">
              {data[imageKey] && (
                <div className="col-span-full mb-4">
                  <img
                    src={data[imageKey]}
                    alt={data[titleKey]}
                    className="detail-image w-full h-auto rounded-md shadow-md"
                  />
                </div>
              )}
              {/* Render simple key-value pairs */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {Object.keys(data).map(
                  (key) =>
                    typeof data[key] !== "object" &&
                    key !== titleKey &&
                    key !== subtitleKey &&
                    key !== imageKey &&
                    renderContent(key, data[key])
                )}
              </div>
              {/* Render nested objects or arrays */}
              <div>
                {Object.keys(data).map(
                  (key) =>
                    typeof data[key] === "object" &&
                    renderContent(key, data[key])
                )}
              </div>
              <div className="mt-6 flex justify-end space-x-4">
                {actions &&
                  actions.map((action, index) => (
                    <Button
                      key={index}
                      label={action.label}
                      icon={action.icon}
                      className={`p-button-rounded ${action.className} p-button-text`}
                      onClick={action.onClick}
                    />
                  ))}
              </div>
            </div>
          </Card>
        )
      )}
    </div>
  );
};

export default DetailPage;
