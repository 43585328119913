// DetailPage.js

import React from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { DetailPage } from '../../../../components';

const Detail = () => {
  const { id } = useParams();
  // const params = useParams();
  const location = useLocation();
  const { endpoint } = location.state;
  console.log(endpoint,'ss')

  return (
  <DetailPage
    apiUrl={`${endpoint.apiUrl}/${id}`}
    titleKey='name'
    subtitleKey="name"
    imageKey="imageUrl"
    // actions={actions}
/>
  );
};

export default Detail;
