import React, { useState, useEffect } from 'react'; 
import { classNames } from 'primereact/utils';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import apiCall from '../../../../api/Api';
import Loader from '../../../../components/Loader';

export default function TemplateDemo({handleShowForm}) {
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        const getSkills = async () => {
            try {
                const response = await apiCall("GET", "skills");
                if (response) {
                    const uniqueSkills = filterChildServices(response);
                    const mappedData = mapApiResponse(uniqueSkills); 
                    setTreeData(mappedData);
                } else {
                    handleAddChild(null, 'empty');
                }
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        getSkills();
    }, []);
    
    // Function to filter out services that are already present as children
    const filterChildServices = (apiData) => {
        // Create a Set to store IDs of services that are children
        const childIds = new Set();
    
        // Loop through the data to collect all children IDs
        apiData.forEach(item => {
            if (item.childrens && item.childrens.length > 0) {
                item.childrens.forEach(child => {
                    childIds.add(child.id);
                });
            }
        });
    
        // Filter out items that are already children
        return apiData.filter(item => !childIds.has(item.id));
    };
    
    // Function to map the API response to the required structure
    const mapApiResponse = (apiData) => {
        const mapItem = (item) => ({
            key: String(item.id),
            label: item.name,
            children: item.childrens ? item.childrens.map(mapItem) : [],
        });
    
        return apiData.map(mapItem);
    };

    const handleAddChild = (nodeKey,node) => {
        console.log("Clicked on node with key:", nodeKey);
        handleShowForm(node)
        // Add your logic to add a child node or perform other actions here
    };

    const nodeTemplate = (node, options) => {
        return (
            <span className={classNames('flex align-items-center', options.className)}>
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-text p-button-sm mr-2"
                    onClick={() => handleAddChild(node.key,node)}
                />
                <span>{node.label}</span>
            </span>
        );
    };

    const togglerTemplate = (node, options) => {
        if (!node) {
            return null;
        }

        const expanded = options.expanded;
        const iconClassName = classNames('p-tree-toggler-icon pi pi-fw', {
            'pi-caret-right': !expanded,
            'pi-caret-down': expanded
        });

        return (
            <button type="button" className="p-tree-toggler p-link" tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    if (treeData === null) return <div className='w-full'><Loader /></div>;
    return (
        <div className="card flex justify-content-center">
            {treeData && (
                <div
                className="p-col-12 p-md-6 p-p-3"
                style={{ overflowY: "auto", maxHeight: "600px" }}
              >
                <Tree 
                    value={treeData} 
                    nodeTemplate={nodeTemplate} 
                    togglerTemplate={togglerTemplate} 
                    className="w-full md:w-30rem"
                />
                </div>
            )}
        </div>
    );
}
