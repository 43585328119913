import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
import '../../../main.css';
import '../../../flags.css';
import apiCall from "../../../../api/Api";
import Loader from '../../../../components/Loader'

const List = () => {
  const [products, setProducts] = useState(null); 
  const [loader, setloader] = useState(false); 
  useEffect(() => {
    getCustomers()
  }, []);

  const getCustomers = async () => {
    try {
      setloader(true)
      const response = await apiCall("GET", "customers");
      console.log(response, 'customer response');
      setProducts(response)
      setloader(false)
    } catch (error) {
      setloader(false); 
      console.error("Failed to Add:", error);
    }
  }
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'email',
      title:'Email',
      sortable:true
    },
    { 
      name:'first_name',
      title:'First Name',
      sortable:true
    },
    { 
      name:'last_name',
      title:'Last Name',
      sortable:true
    },
    // { 
    //   name:'country',
    //   title:'Country'
    // },
    // { 
    //   name:'mobile',
    //   title:'Mobile'
    // },
    
    { 
      name:'gander',
      title:'Gender',
      sortable:true
    },
    { 
      name:'target_radius',
      title:'Target Raduis',
      sortable:true
    },
    { 
      name:'status',
      title:'Status',
      type:'status',
      kind:'easy-edit',
      postUrl:'/customer-status',
      dropdownOptions:[
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "InActive", value: "inactive" },
        { label: "Suspended", value: "suspended" },
      ]
    },
  ]; 

      // Loader here
  if (loader === true) return <Loader/>;
  return (
    <div>
      <Company 
      title="Users" 
      data={products} 
      column={column} 
      addRoute='/customer/add'  
      detailRoute={{route: 'customers',apiUrl:'customers'}} s
      howDetailsRoute={true}
      fetchDataAgain={getCustomers}
      />
    </div>
  );
};

export default List;
