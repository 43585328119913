import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
// import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';
import apiCall from '../api/Api';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import EasyEdit from 'react-easy-edit';

export default function Company({fetchDataAgain,loader,setloader,showCustomFilter,showAddtionalButton,toggleFilters,showReviewButton,showFurtherDetailButton,showEdit,data,column,addRoute,title,detailRoute,showDetailsRoute,showExportButton,rowSelectionMode,...props}) {
    let emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };

    const [products, setProducts] = useState(data);
    // const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    // const [submitted, setSubmitted] = useState(false);
    const [filters] = useState({
        // 'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [statuses] = useState(toggleFilters);
    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate()


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setProduct(emptyProduct);
        navigate(addRoute)
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const editProduct = (product) => {
        setProduct({ ...product });
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = async () => {
        let _products = products.filter((val) => val.id !== product.id);


        // Delete Logic with API
        try {
            const response = await apiCall("DELETE",`${detailRoute?.apiUrl}/${product?.id}`);
            console.log(response, 'skills response');
              setProducts(_products);
              setDeleteProductDialog(false);
              setProduct(emptyProduct);
              toast.current.show({ severity: 'success', summary: 'Successful', detail: `${detailRoute?.title} Deleted`, life: 3000 });
          } catch (error) {
            console.error("Failed to Add:", error);
            toast.current.show({ severity: 'danger', summary: 'Failed', detail: 'Error Occur', life: 3000 });

          }
        
    };

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // };
    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));

        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    };

    const leftToolbarTemplate = () => {
        return (
            addRoute && <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={ openNew} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return showCustomFilter && showCustomFilter()
    //    return showExportButton && <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    }

    const imageBodyTemplate = (rowData) => {
        // Image Url from the API 
        return <img src={`${rowData.image}`} alt={`${rowData?.id}`} className="shadow-2 border-round" style={{ width: '64px' }} />;
    };

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

  

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown 
            value={options.value} 
            options={statuses} 
            onChange={(e) => options.filterApplyCallback(e.value)} 
            itemTemplate={statusItemTemplate} 
            placeholder="Select One" 
            className="p-column-filter" 
            showClear 
            style={{ minWidth: '12rem' }} 
            />
        );
    };

    // const statusBodyTemplate = (rowData,name,col) => {
    //     console.log(name,'rowData')
    //     console.log(rowData,'aaa')
    //     return <Tag value={rowData[name.field] === 1 ? 'Active' : 'InActive'} severity={getSeverity(rowData[name.field])}></Tag>;
    // };

    const handleStatusChange = async (data,rowData,col) => {
        console.log(rowData,'rowData')
        console.log(data,'data')
        const response = await apiCall("POST",`${col?.postUrl}/${rowData?.id}`,{status:data?.props !== undefined ? data?.props?.value : data});
        if(response){
            fetchDataAgain()
        }

    }

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };
    const statusBodyTemplate = (rowData, name, col) => {
        console.log(col,'col')
        const statusValue = rowData[name.field];
        const displayValue = getStatusDisplayValue(statusValue);
        const severity = getSeverity(displayValue);

        if(col?.kind === 'easy-edit'){
            return <EasyEdit
            type="select"
            options={col?.dropdownOptions}
            onSave={(data) => handleStatusChange(data,rowData,col)}
            value= {<Tag value={displayValue} severity={severity}></Tag>}
          //   instructions="Custom instructions"
          />
        }else{
          return  <Tag value={displayValue} severity={severity}></Tag>
        }
        // }else {
        //    return <EasyEdit
        //       type="select"
        //       options={col?.dropdownOptions}
        //       onSave={(data) => handleStatusChange(data,rowData,col)}
        //       value= {<Tag value={displayValue} severity={severity}></Tag>}
        //     //   instructions="Custom instructions"
        //     />;
        // }
      
      };
      
    
    const furtherDetail = (rowData) => {
        navigate(`/geek-skills/${rowData?.id}`)
    }
    const geekReviews = (rowData) => {
        navigate(`/geek-reviews/${rowData?.id}`,{state:{geekName : rowData?.first_name}})
    }

    const actionBodyTemplate = (rowData) => {
        // console.log(rowData.actionButton,'aaaa data')
        return (
            <React.Fragment>
                {showAddtionalButton && showAddtionalButton(rowData)}
               { showEdit &&  <Button icon="pi pi-pencil" rounded outlined className="m-1" onClick={() => editProduct(rowData)} />}
                <Button icon="pi pi-trash" rounded outlined className="m-1" severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
               { showFurtherDetailButton &&   <Button icon="pi pi-user" className="m-1" rounded outlined severity="danger" onClick={() =>  furtherDetail(rowData)} />}
               { showDetailsRoute &&  <Button icon="pi pi-book" className="m-1" rounded outlined severity="secondary" onClick={() =>  navigate(`/${detailRoute?.route}/${rowData?.id}/detail`,{state:{endpoint : detailRoute}})} />} 
               { showReviewButton &&  <Button icon="pi pi-star" className="m-1" rounded outlined severity="secondary" onClick={() =>  geekReviews(rowData)} />}
            </React.Fragment>
        );
    };

    // const buttonsBodyTemplate = (rowData) => {
    //     return (
    //         <React.Fragment>
    //             <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
    //             <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
    //            { showDetailsRoute &&  <Button icon="pi pi-book" rounded outlined severity="secondary" onClick={() =>  navigate(`/${detailRoute}/${rowData?.id}/detail`)} />}
    //         </React.Fragment>
    //     );
    // }

    // const getSeverity = (value) => {
    //     switch (value) {
    //         case 1:
    //             return 'success';

    //         case 'LOWSTOCK':
    //             return 'warning';

    //         case 0:
    //             return 'danger';

    //         default:
    //             return null;
    //     }
    // };
    const formatterBodyTemplate = (rowData, name, col) => {
        const data = rowData?.[name];
      
        if (Array.isArray(data)) {
          // If data is an array, map over it to create a formatted string
          const formattedArray = data.map(item => {
            if (item && typeof item === 'object') {
              // Create a string based on the dynamic keys
              const formattedString = col?.keys?.map(key => item[key]).filter(Boolean).join(', '); // Join keys with ', '
              return formattedString;
            }
            return ''; // Handle unexpected types or empty items
          }).filter(Boolean).join(' | '); // Join array elements with a delimiter like ' | '
      
          return <p>{formattedArray}</p>;
        } else if (data && typeof data === 'object') {
          // If data is a single object, format it directly
          const formattedString = col?.keys?.map(key => data[key]).filter(Boolean).join(', ');
          return <p>{formattedString}</p>;
        } else {
          // Handle cases where data is neither an object nor an array
          return <p>No data available</p>;
        }
      };
      
    const getStatusDisplayValue = (value) => {
        // Adjust this function based on how your status values map to display values
        switch (value) {
          case 1:
            return 'Active';
          case 0:
            return 'InActive';
          default:
            return value;
        }
      };
      
      const getSeverity = (value) => {
        console.log(value,'vaa')
        switch (value) {
          case "Active":
            return "success";
            case "active":
            return "success";  
          case "InActive":
            return "danger";
            case "inactive":
                return "danger";
            case "yes":
            return "success";
          case "no":
            return "danger";
          case "Pending":
            return "warning";
            case "pending":
            return "warning";
          case "Suspended":
            return "info";
            case "suspended":
            return "info";

          case "Completed":
            return "success";
          case "Cancelled":
            return "danger";
          case "Scheduled":
            return "warning";
          case "Confirmed":
            return "info";
          default:
            return null;
        }
      };
      

    const getBody = (name,type,col) => {
        switch (type) {
            case 'image':
                return imageBodyTemplate;

            case 'price':
                return priceBodyTemplate;

            case 'status':
                return (rowData,name) => statusBodyTemplate(rowData,name,col);
            
            case 'rating':
               return ratingBodyTemplate;
            
            case 'formatter':
               return (rowData) => formatterBodyTemplate(rowData,name,col);
            

            default:
                return null;
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between bg-orange-300 text-white rounded">
            <h3 className="m-3">{title}</h3>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
            <Button className='ml-6' label="Yes" icon="pi pi-check" severity="danger" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <Toolbar className="mb-4"  start= {leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                <DataTable 
                loadin={loader}
                ref={dt} 
                value={products} 
                selection={selectedProducts} 
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"  
                paginator 
                rows={10} 
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
                filters={filters} 
                filterDisplay="row"  
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" 
                header={header}
                >
                    
                    <Column selectionMode={rowSelectionMode} exportable={false}></Column>
                    {
                        column.map((col) =>  <Column key={col.name} 
                            field={col.name}
                            header={col.title} 
                            // body={col.name =='image' ? imageBodyTemplate : null} 
                            body={getBody(col.name,col.type,col)} 
                            sortable={col.sortable}
                            style={{ minWidth: '8rem' }}
                            showFilterMenu={false} 
                            filterMenuStyle={{ width: '14rem' }} 
                            filter={col.showFilter}
                            filterElement={col?.showFilterElement ? statusRowFilterTemplate : null} 
                            >           
                    
                    </Column>
                )
            }
            {/* Action Column */}
            <Column header="Actions" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                   
                </DataTable>
            </div>

            <Dialog visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{product.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog>
        </div>
    );
}
        