import React,{useState} from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import TemplateDemo from './TreeComponent'; // Assuming the tree component is in TemplateDemo.js
import Add from './CategoryForm';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import apiCall from '../../../../api/Api'
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import {useToast} from '../../../../hooks/useToast'

export default function AddCategoryPage() {

    const [showForm, setShowForm] = useState(false)
    const [parentId, setParentId] = useState(null)
    const [parentName, setParentName] = useState('')
    const [level, setLevel] = useState(1);
    const navigate = useNavigate();
    const {toast,showSuccess} =  useToast()

    const handleShowForm = (data) => {
      if(data === 'empty'){
        setShowForm(true)
        setParentId(null)
        setParentName('')
      }
        console.log(data,'node data')
        setShowForm(true)
        setParentId(data?.key)
        setParentName(data?.label)
    }

    const schema = yup.object({
      name: yup.string().required("Name is required."),
      // service_type: yup.number().required("Parent Category is required."),
      // parent_id: yup.number().required("Parent Category is required."),
      // parent_id: yup.number().required("Parent Category is required."),
      // is_featured: yup.boolean().required("Is Featured is required."),
      // image: yup.string().required("Image is required."),
      
    });

    const {register,control,setValue, handleSubmit,formState: { errors }} = useForm({ resolver: yupResolver(schema) });


  const footer = (
    <div className="flex h-[calc(20vh-100px)] justify-start items-center outline-none gap-3">
      <Button
        label="Cancel"
        className={`rounded bg-[#dc3545] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-red-400 mb-5`}
      />
      <Button
        type="submit"
        label="Create"
        className={`rounded bg-[#198754] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-green-400 mb-5`}
      />
    </div>
  );

const onSubmit = async (data) => {
  console.log(data,'data')
  let serviceType = 1 ;
  if(data.service_type === 1){
    serviceType = 'onsite'
  }else if (data.service_type === 2){
     serviceType = 'offsite'
  }else if (data.service_type === 3){
      serviceType = 'both'
  }

  let new_data = {
    ...data,
   service_type:serviceType,
   is_featured: data.is_featured === true ? 'yes' : 'no',
   parent_id:parentId
  }
  console.log("Data:",new_data);

  
  try {
    const response = await apiCall("POST", 'skills', new_data,null,true);
    // console.log(response, 'response');
    if(response){
      showSuccess('Added Successfully!')
      navigate(`/categories`);
    }
  } catch (error) {
    console.error("Failed to Add:", error);
  }
};



  return (
    <> <Toast ref={toast} />
    <div className="p-m-6">
       <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        className="p-shadow-5 p-p-3"
        style={{
          margin: "5%",
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
        }}
        footer={footer}
      >
        <h2 className="p-card-title p-text-center">Add Category</h2>
        <Divider />
        <div className="p-grid p-nogutter" style={{ flex: 1, display: "flex" }}>
            <TemplateDemo handleShowForm={handleShowForm} />
          <div
            className="p-col-12 p-md-6 p-p-3 w-full"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderLeft: "1px solid #dee2e6",
            }}
          >
            <div>
              {/* <h3>Your Component Here</h3> */}
            

              {showForm && <> 
                <h2 className="p-card-title p-text-center"> Add {parentName} Child</h2>
              <div className="card flex justify-content-center">
                <div className="flex flex-wrap gap-3">
                 
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="1"
                      name="level"
                      value={1}
                      onChange={(e) => setLevel(e.value)}
                      checked={level === 1}
                    />
                    <label htmlFor="1" className="ml-2">
                      Sub Child
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton
                      inputId="2"
                      name="level"
                      value={2}
                      onChange={(e) => setLevel(e.value)}
                      checked={level === 2}
                    />
                    <label htmlFor="2" className="ml-2">
                      Last Child
                    </label>
                  </div>
                </div>
              </div>
               <Add main={false} level={level} schema={schema} errors={errors} control={control} register={register} setValue={setValue}/>  </>} 
            </div>
          </div>
        </div>
      </Card>
      </form>
    </div>
    </>
  );
}
