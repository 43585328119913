import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import Loader from "../../../../components/Loader";
import apiCall from "../../../../api/Api";

const List = () => {
  const [products, setProducts] = useState(null); 

  useEffect(() => {
    const getWalletTransactions = async () => {
      try {
        const response = await apiCall("GET", `wallet-transactions`);
        console.log(response, 'wallet-with-transactions response');
        setProducts(response)
      } catch (error) {
        console.error("Failed ", error);
      }
    }
    getWalletTransactions()
    // ProductService.getProducts().then((data) => setProducts(data));
  }, []);
  
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'description',
      title:'Detail',
      sortable:true
    },
    { 
      name:'payment_source',
      title:'Payment Source',
      sortable:true
    },
    { 
      name:'job.service_name',
      title:'Job Title',
      sortable:true
    },
    { 
      name:'job.description',
      title:'Job Detail',
      sortable:true
    },
    { 
      name:'user.user.first_name',
      title:'Customer Name',
      sortable:true
    },
    { 
      name:'debit',
      title:'Debit',
      sortable:true
    },
    { 
      name:'credit',
      title:'Credit',
      sortable:true
    },
 
    { 
      name:'currency',
      title:'Currency',
      sortable:true
    },

  ]; 


      // Loader here
  if (products === null) return <Loader/>;
  return (
    <div>
      <Company title='All Wallet Transactions' data={products} column={column} detailRoute='transactions'/>
    </div>
  );
};

export default List;
