import React, { useState, useEffect } from "react";
import Company from "../../../../components/Datatable";
// import { ProductService } from "../../../../utils/data";
import '../../../main.css';
import '../../../flags.css';
import apiCall from '../../../../api/Api'
import Loader from '../../../../components/Loader'
import { Button } from 'primereact/button';
import { useLocation, useNavigate, useParams } from "react-router-dom";
const List = () => {

  const [products, setProducts] = useState(null);
  const [title, setTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const {id} = useParams()
  const location = useLocation();
  useEffect(() => {
    // ProductService.getProducts().then((data) => setProducts(data));
    setLoader(true)
    const getSkills = async () => {
      try {
        const response = await apiCall("GET", `sub-skills/${id}`);
        console.log(response, 'skills response');
        setProducts(response)
        setLoader(false)
        const {title} = location.state
        setTitle(title)
        console.log(title,'title')
        // navigate('/companies');
      } catch (error) {
        console.error("Failed to Add:", error);
      }
    }
    getSkills()
    
  }, [id,location.state]);
  
  // columns here from the API
  const column = [
    { 
      name:'id',
      title:'ID',
      sortable:true
    },
    { 
      name:'name',
      title:'Name',
      sortable:true
    },
    { 
      name:'service_type',
      title:'Service Type',
      sortable:true
    },
  
    { 
      name:'one_off_cost_remote',
      title:'One Off Cost Remote',
      sortable:true
    },
    { 
      name:'one_off_cost_onsite',
      title:'One Off Cost OnSite',
      sortable:true
    },
    { 
      name:'per_hour_cost_remote',
      title:'Per Hour Remote',
      sortable:true
    },
    { 
      name:'per_hour_cost_onsite',
      title:'Per Hour OnSite',
      sortable:true
    },

    { 
      name:'image',
      title:'Image',
      type:'image'
    },
    { 
      name:'is_featured',
      title:'Is Featured',
      type:'status'
    },
  
    
  ]; 


  const handleDispute = (rowData)  => {
    console.log('clicked',rowData)
    navigate(`/sub-categories/${rowData?.id}`,{state:{title:rowData?.name}})
  }


  const resolveDispute = (rowData) =>  <Button icon="pi pi-plus-circle" rounded outlined className="m-1" onClick={() => handleDispute(rowData)} />


      // Loader here
      if (loader === true) return <Loader/>;
  return (
    <div>
      <Company 
      title={`${title} Skills`}
      data={products} 
      column={column} 
      addRoute='/categories/add' 
      showDetailsRoute={true} 
      detailRoute='skills'
      showExportButton={false}
      rowSelectionMode='none'
      showAddtionalButton={resolveDispute}
      />
    </div>
  );
};

export default List;
