import React,{useEffect , useState} from 'react';
import { useForm,Controller,useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
// import { classNames } from 'primereact/utils';
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import apiCall from '../../../../api/Api'
// import { ToggleButton } from "primereact/togglebutton";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputSwitch } from 'primereact/inputswitch';
// import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';  
import { Dialog } from 'primereact/dialog';

 const Form = ({schema,title,fields,getVisaType,apiRoute,dynamicFieldsArray}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [buttonsLabels, setButtonsLabels] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdowns, setDropdowns] = useState([{ id: 1, options: [], value: null }]);
  // const [subSkills, setSubSkills] = useState([]);
  // const [files, setFiles] = useState({})
  const {register,setValue,control, watch, handleSubmit,formState: { errors }} = useForm({
        defaultValues: {
          skills: dynamicFieldsArray,
          license: null,
          insurance: null,
        },
         resolver: yupResolver(schema) 
    });

    const { fields: skillFields , append, remove } = useFieldArray({
        control,
        name: 'skills'
      });

  const visaType = watch('visa_type');

  useEffect(() => {
    getVisaType && getVisaType(visaType)
  }, [visaType])

const onSubmit = async (data) => {
  // Assuming `files` contains the file objects
  // const combinedData = { ...data, ...files };
  // e?.stopPropagation()
  // e?.preventDefault()
  const combinedData = { ...data };
  console.log(combinedData,'combine data')
  try {
    // Make the API call
    const response = await apiCall("POST", apiRoute, combinedData,null,true);
    console.log('Response:', response);
    // Optionally navigate or update UI here
    navigate(`/${apiRoute}`);
  } catch (error) {
    console.error("Failed to Add:", error);
  }
};


  const footer = <div className="flex h-[calc(20vh-100px)] justify-start items-center outline-none gap-3">
    <Button  label="Cancel" className={`rounded bg-[#dc3545] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-red-400 mb-5`}/>
    <Button  type='submit' label="Create" className={`rounded bg-[#198754] text-[#F5F7FF] w-[12rem] p-3 mt-2 hover:bg-green-400 mb-5`}/>
  </div>

const getFormErrorMessage = (name) => {
    console.log(name,'name')
//   return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
};


const handleImageUpload = async (e,name) => {
  const file = e.files[0];
  console.log(file,'file')
  // setFiles({
  //   ...files,
  //   [name]:file
  // })
  // if (file) {
  //   try {
  //     // Convert file to Base64
  //     const base64File = await fileToBase64(file);
  //     setFiles({
  //       ...files,
  //       [name]:base64File
  //     })
  //   } catch (error) {
  //     console.error('Error converting file to Base64:', error);
  //   }
  // }
 
  setValue(name,e.files[0])
}

const dateHandler= (field,value) => {
  console.log(field,'field')
  console.log(value,'value')
  const date = new Date(value);
  
  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based index
  const year = date.getFullYear();
  
  // Format as dd-mm-yyyy
  const formattedDate = `${year}-${month}-${day}`;
  field.onChange(formattedDate)
  
  // console.log(formattedDate); // Output: 15-08-2024
  
  }
  // const fetchSubSkills = async (id, index) => {
  //   console.log(id,'id')
  //   try {
  //     let _subSkills = subSkills
  //     const response = await apiCall('GET',`/sub-skills/${id}`);
  //     if (response.length > 0) {
  //       let skl = response?.map(sk => (
  //         {
  //           name: sk.name,
  //           code : sk.id
  //         }
  //     ))
  //     _subSkills.push(skl)
  //       setSubSkills([..._subSkills]);
  //     }
  //   } catch (error) {
  //     console.error('Failed to fetch sub-skills:', error);
  //   }
  // };
  

  const handleModalOpen = (options,index) => {
    setDropdowns([{ id: 1, options: options, value: null,dynamicFieldIndex:index }]);
    setVisible(true)
    setDropdownOptions(options)
  }

  const handleRepeater =() => {

    append({ skill_id: '', one_off_cost_remote: '', one_off_cost_onsite: '', self_score: 1 })
    setDropdowns([{ options:dropdownOptions , id: 1, value: null }])
  }

  const body = (value) => {
    switch (value.type) {
      case "text":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel>
              <InputText
                {...register(value.name)}
                type={value.type}
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem] m-1 mt-3 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-3 m-1 focus:outline-none focus:ring-1"
                }
              />
              <label htmlFor="username" style={{ width: "200px" }}>
                {value.placeholder}
              </label>
            </FloatLabel>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );

      case "dropdown":
        return (
          <Col xs={12} md={4} xl={4} className="mb-5">
            <FloatLabel className="w-full">
              <Controller
                name={value.name}
                control={control}
                // rules={{ required: 'Required.' }}
                render={({ field }) => (
                  <div className="flex w-full">
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={value.options}
                      optionLabel="name"
                      focusInputRef={field.ref}
                      optionValue="code"
                      // placeholder={field.placeholder}
                      className="border border-[#AEBBCD] mt-3"
                      filter
                      showClear
                      style={{width:'350px'}}
                    />
                    {/* {getFormErrorMessage(field.name)} */}
                  </div>
                )}
              />
              <label htmlFor={value.name}> {value.placeholder} </label>
            </FloatLabel>
          </Col>
        );

      case "multi-select":
        return (
          <div className={`col-${value.column}`}>
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white font-bold text-left"
            >
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <>
                  <MultiSelect
                    id={field.name}
                    //  name="value"
                    value={field.value}
                    options={value.options}
                    onChange={(e) => field.onChange(e.value)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder={value.placeholder}
                    maxSelectedLabels={3}
                    className="w-full border border-[#AEBBCD] mt-5"
                    filter
                    showClear
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
        );

      case "image":
        return (
          // <div className={`col-${value.column}`}>
          <Col xs={12} md={4} xl={4} className="mb-5">
             {/* <FloatLabel> */}
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            {/* <div className="card"> */}
              <FileUpload
                {...register(value.name)}
                // ref={{...register(value.name)}}
                name={value.name}
                // url={"/api/upload"}
                multiple={value.multiple}
                accept="image/*"
                maxFileSize={1000000}
                // customUpload 
                onSelect={(e) => handleImageUpload(e,value.name)}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
                className={
                  errors[value.name]
                    ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                    : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
                }
              />
            {/* </div> */}
            {/* </FloatLabel> */}
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </Col>
        );

      case "textarea":
        return (
          <div className="col-4">
            <label
              htmlFor={value.name}
              className="text-md text-black dark:text-white font-bold text-left"
            >
              {value.placeholder}
            </label>
            <textarea
              {...register(value.name)}
              rows="4"
              cols="80"
              className={
                errors[value.name]
                  ? "block peer rounded-[5px] w-[25rem]  mt-5 border-[#C93B32] focus:outline-none focus:border-[#C93B32]  focus:ring-1 focus:ring-[#C93B32]"
                  : "block peer rounded-[5px] border-[#AEBBCD] w-[25rem] mt-5 focus:outline-none focus:ring-1"
              }
            ></textarea>
            <span className="place-self-start text-[14px] text-[#C93B32]">
              {errors[value.name]?.message}
            </span>
          </div>
        );
   
      case "datetime":
        return (
          <Col
          xs={12}
          md={4}
          xl={4}
          className="w-full"
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
           <FloatLabel className="w-full">
           
            <Controller
              name={value.name}
              control={control}
              rules={{ required: "Date is required." }}
              render={({ field, fieldState }) => (
                <div>
                  <Calendar
                    inputId={field.name}
                    value={field.value}
                    onChange={(e)=>  dateHandler(field,e?.target?.value) }
                    dateFormat="dd/mm/yy"
                    className="rounded border-[#AEBBCD] w-[25rem] focus:outline-none focus:ring-1 mt-3"
                    showTime
                    hourFormat="12"
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
             <label
              htmlFor={value.name}
              // className="text-md text-black dark:text-white text-left"
            >
              {value.placeholder}
            </label>
            </FloatLabel>
          </Col>
        );

      case "dynamicFields":
        return (
          <Col
            xs={12}
            md={12}
            xl={12}
            className="w-full"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            
            {skillFields.map((item, index) => (
              <div key={item.id} style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', alignItems: 'center' }}>
                
                <div style={{ flex: '1 1 calc(15.2857% - 16px)', minWidth: '150px' }}>
                  {/* <FloatLabel>
                    <Controller
                      name={`skills[${index}].skill_id`}
                      control={control}
                      render={({ field }) => (
                        <div style={{ width: '100%' }}>
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                              // fetchSubSkills(e.value, index); // Fetch sub-skills when a skill is selected
                            }}
                            options={value.options}
                            optionLabel="name"
                            focusInputRef={field.ref}
                            optionValue="code"
                            className="w-full border border-[#AEBBCD] mt-4"
                            filter
                            showClear
                          />
                        </div>
                      )}
                    />
                    <label htmlFor={value.name}> {value.placeholder} </label>
                  </FloatLabel> */}
                   {/* <div className="flex flex-wrap gap-2"> */}
                  { console.log(buttonsLabels,'buttons')}
                <Button className='mt-3' label={buttonsLabels[index] || 'Choose'} type='button' icon="pi pi-plus" severity="success" onClick={() => handleModalOpen(value?.options,index)} />
            {/* </div> */}
                </div>
        {/* {console.log(subSkills,'ssubskills')}
          {subSkills[index]?.map((item, index) => (
            <div key={item?.code} style={{ flex: '1 1 calc(14.2857% - 16px)', minWidth: '150px' }}>
              <FloatLabel>
              <Controller
                name={`skills[${index}].sub_skill_id`}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      fetchSubSkills(e.value, index + 1); // Fetch deeper sub-skills
                    }}
                    options={subSkills[index]} // Populate with fetched sub-skills
                    optionLabel="name"
                    optionValue="code"
                    className="w-full border border-[#AEBBCD] mt-4"
                    filter
                    showClear
                  />
                )}
              />
                 <label htmlFor={item.label}> {item.label} </label>
              </FloatLabel>
            </div>
          ))} */}


          {/* Dynamic Array  */}

                {/* <div style={{ flex: '1 1 calc(14.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].one_off_cost_remote`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="number"
                          min={0}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].one_off_cost_remote`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].one_off_cost_remote`}> One Off Cost Remote </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].one_off_cost_remote`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div> */}
        
                {/* <div style={{ flex: '1 1 calc(14.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].one_off_cost_onsite`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="number"
                          min={0}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].one_off_cost_onsite`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].one_off_cost_onsite`}> One Off Cost Onsite </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].one_off_cost_onsite`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div> */}
        
                {/* <div style={{ flex: '1 1 calc(14.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].per_hour_cost_onsite`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="number"
                          min={0}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].per_hour_cost_onsite`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].per_hour_cost_onsite`}> Per Hour Cost Onsite </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].per_hour_cost_onsite`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div> */}
        
                {/* <div style={{ flex: '1 1 calc(14.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].per_hour_cost_remote`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="number"
                          min={0}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].per_hour_cost_remote`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].per_hour_cost_remote`}> Per Hour Cost Remote </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].per_hour_cost_remote`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div> */}

                <div style={{ flex: '1 1 calc(15.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].self_score`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="number"
                          min={0}
                          max={10}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].self_score`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].self_score`}> Self Score </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].self_score`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div>
        
                <div style={{ flex: '1 1 calc(15.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].other_details`}
                    control={control}
                    render={({ field }) => (
                      <FloatLabel>
                        <InputText
                          {...field}
                          type="text"
                          min={0}
                          className={`block peer rounded-[5px] w-full mt-3 ${
                            errors[`skills[${index}].other_details`]
                              ? 'border-[#C93B32] focus:border-[#C93B32] focus:ring-[#C93B32]'
                              : 'border-[#AEBBCD]'
                          }`}
                        />
                        <label htmlFor={`skills[${index}].other_details`}> Other Details </label>
                        <span className="text-[14px] text-[#C93B32]">
                          {errors[`skills[${index}].other_details`]?.message}
                        </span>
                      </FloatLabel>
                    )}
                  />
                </div>
        
                <div style={{ flex: '1 1 calc(25.2857% - 16px)', minWidth: '150px' }}>
                  <Controller
                    name={`skills[${index}].certificate`}
                    control={control}
                    render={({ field }) => (
                      <FileUpload
                        name={field.name}
                        onSelect={(e) => setValue(`skills[${index}].certificate`, e.files[0])}
                        accept="image/*"
                        maxFileSize={1000000}
                        chooseLabel="Upload Certificate"
                        emptyTemplate={
                          <p className="m-0">Drag and drop files here to upload.</p>
                        }
                        className="w-full mt-3"
                      />
                    )}
                  />
                  <span className="place-self-start text-[14px] text-[#C93B32]">
                    {errors[`skills[${index}].certificate`]?.message}
                  </span>
                </div>
        
                <div style={{ flex: '1 1 calc(15.2857% - 16px)', minWidth: '150px', display: 'flex', gap: '8px', justifyContent: 'start' }}>
                  <Button
                    type="button"
                    label="Add"
                    onClick={handleRepeater}
                    className="rounded-full bg-[#502bd5] text-[#F5F7FF] w-10rem p-3 hover:bg-green-400"
                  />
                  {index > 0 && (
                    <Button
                      type="button"
                      label="Remove"
                      onClick={() => remove(index)}
                      className="rounded-full bg-[#dc8335] text-[#F5F7FF] w-10rem p-3 hover:bg-red-400"
                    />
                  )}
                </div>
        
              </div>
            ))}
          </Col>
        );
    
      case "toggle":
        return (
          <Col
            xs={12}
            md={4}
            xl={4}
            className="ml-5 w-100"
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
          >
            <label className="" htmlFor={value.name}>
              {value.placeholder}
            </label>
            <Controller
              name={value.name}
              control={control}
              render={({ field }) => (
                <div className="">
                  <InputSwitch
                    name={field.name}
                    // value={field.value || false}
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </Col>
        );

      case "space":
           return <div style={{width:'100vw'}}></div>

    case "heading":
        return <div style={{width:'80vw',marginBottom:'5%',marginTop:'3%', fontSize:18}}> <h4 className='font-bold h-2'>{value.placeholder}</h4> </div>
      
        default:
        return null;
    }
  };

  const handleSelect = () => {
    console.log(dropdowns[0]?.dynamicFieldIndex,'dropdowns[0]?.dynamicFieldIndex')
    console.log(dropdowns,'dropdowns')
    setVisible(false)
    setDisabled(true)

    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].skill_id`,dropdowns[dropdowns?.length - 1]?.value?.code)
    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].is_one_off`,dropdowns[dropdowns?.length - 1]?.value?.values?.is_one_off)
    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].one_off_cost_onsite`,dropdowns[dropdowns?.length - 1]?.value?.values?.one_off_cost_onsite)
    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].one_off_cost_remote`,dropdowns[dropdowns?.length - 1]?.value?.values?.one_off_cost_remote)
    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].per_hour_cost_onsite`,dropdowns[dropdowns?.length - 1]?.value?.values?.per_hour_cost_onsite)
    setValue(`skills[${dropdowns[dropdowns?.length - 1]?.dynamicFieldIndex}].per_hour_cost_remote`,dropdowns[dropdowns?.length - 1]?.value?.values?.per_hour_cost_remote)

    setButtonsLabels((prev) => {
      const newButtonsLabels = [...prev]; // Create a copy of the current state
      // Update the value at the specific index with the new concatenated value
      newButtonsLabels[dropdowns[0]?.dynamicFieldIndex] = `${dropdowns[dropdowns?.length - 2]?.options[0]?.name} -> ${dropdowns[dropdowns?.length - 1]?.options[0]?.name} `;
      return newButtonsLabels; // Return the updated array to set the new state
    });
    
  }

  const footerContent = (
    <div>
        <Button disabled={disabled} label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
        <Button  disabled={disabled} label="Select" icon="pi pi-check" onClick={ handleSelect } autoFocus />
    </div>
);
  
 // Function to handle selection change
 const handleDropdownChange = (e, index) => {
  const updatedDropdowns = [...dropdowns];
  updatedDropdowns[index].value = e.value;
  setDropdowns(updatedDropdowns);

  // Fetch options for the next dropdown
  console.log(e,'eeee')
  fetchOptions(e.value, index);
};
const fetchOptions = async (selectedValue, index) => {
  console.log(selectedValue,'selected value')
  let id = selectedValue?.code === undefined ? selectedValue : selectedValue?.code
  try {
    const response = await apiCall('GET',`/sub-skills/${id}`);
    const newOptions = response?.map(sk => (
      {
        name: sk.name,
        code : sk.id,
        values:{
          ...sk
        }
      }
  ))

    if (newOptions.length > 0) {
      // Reset dropdowns that come after the current one
      const newDropdowns = dropdowns.slice(0, index + 1);

      // Append the new dropdown options
      newDropdowns.push({
        dynamicFieldIndex:dropdowns[0].dynamicFieldIndex,
        id: newDropdowns.length + 1,
        options: newOptions,
        value: null,
      });

      setDropdowns(newDropdowns);
    } else {
      // If no further options, just keep the dropdowns up to this point
      setDropdowns(dropdowns.slice(0, index + 1));
      setDisabled(false)
    }
  } catch (error) {
    console.error('Error fetching options:', error);
  }
};


  return (
    <>
      <Dialog header="Please Choose" visible={visible} style={{ width: '50vw' }} 
          onHide={() => {if (!visible) return; setVisible(false); }}
           footer={footerContent}>
                 <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {dropdowns.map((dropdown, index) => (
        <Dropdown
          key={dropdown.id}
          value={dropdown.value}
          options={dropdown.options}
          onChange={(e) => handleDropdownChange(e, index)}
          optionLabel="name"  // Assuming your API returns 'name' for display
          optionValue="id"    // Assuming your API returns 'id' as value
          placeholder={`Select Option`}
          className="w-full p-inputtext-lg"
          filter
          // showClear
        />
      ))}

      {/* Reset Button to Clear Dropdowns */}
      <Button
        label="Reset"
        className="p-button-danger"
        style={{width:'20%'}}
        onClick={() => setDropdowns([{ options:dropdownOptions , id: 1, value: null }])}
      />
    </div>
            </Dialog>

            <div style={{margin:'18px'}} className='mt-5'>
      <form onSubmit={handleSubmit(onSubmit)}>
         <Card title={`Add ${title}`} footer={footer} style={{maxWidth:'100%',width:'auto'}}>
        <div className="flex flex-wrap">
            {
                fields?.map(field => 
                    <React.Fragment key={field.name}>
                      <Row>
                         {body(field)}
                      </Row>
                    </React.Fragment>
                         )
            }
        </div>
        </Card>
      </form>
    </div>
    </>
   
  );
}


export default Form;